<template>
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-card-text>
                    <v-form>
                        <v-row class="mb-n10">
                            <v-col class="px-1 pl-md-1 pr-lg-3 d-flex">
                                <v-col class="mt-n3 mb-3">
                                    <InputDatePicker
                                        :disabled="carregando"
                                        v-model="busca.dtinicio"
                                        label="Data de vencimento inicial"
                                        :outlined="true"
                                        :dense="true"
                                    />
                                </v-col>
                                <v-col class="mt-n3 mb-3 px-0">
                                    <InputDatePicker
                                        :disabled="carregando"
                                        v-model="busca.dtfim"
                                        label="Data de vencimento final"
                                        :outlined="true"
                                        :dense="true"
                                    />
                                </v-col>
                                <v-btn
                                    :disabled="carregando"
                                    class="ml-2 mt-1"
                                    color="primary"
                                    elevation="0"
                                    fab
                                    x-small
                                    @click="get()"
                                >
                                    <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card color="primary" class="white--text">
                <v-progress-linear indeterminate absolute :active="carregando" />
                <v-card-title class="text-truncate">
                    Financeiro - Contas a Receber
                    <v-spacer/>
                    <v-btn
                        :disabled="carregando"
                        elevation="0"
                        class="primary--text"
                        @click="exportaPlanilha()"
                    >
                        <v-icon class="pr-0 pr-md-2">mdi-file-excel-outline</v-icon>
                        <span class="d-none d-sm-block">Exportar em Excel</span>
                    </v-btn>
                </v-card-title>
                <v-card-text v-if="carregando">
                    <v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
                </v-card-text>
                <v-card-text v-else>
                    <v-data-table
                        mobile-breakpoint="0"
                        v-if="dados"
                        :dark="false"
                        dense
                        :items-per-page="1000"
                        :headers="[
                            {text: 'Cód.', value: 'idempresa', class:'text-truncate'},
                            {text: 'Empresa', value: 'empresa', class:'text-truncate pl-0'},
                            {text: 'Valor Previsto', value: 'valorprevisto', class:'text-truncate pl-0'},
                            {text: 'Valor Recebido', value: 'valorpgto', class:'text-truncate pl-0'},
                            {text: 'Valor Pendente', value: 'valorsaldo', class:'text-truncate pl-0'},
                            {text: 'Venc. até 30 dias', value: 'valorsaldo30dias', class:'text-truncate pl-0'},
                            {text: '% Venc. até 30 dias', value: 'valorpercsaldo30dias', align:'center', class:'text-truncate pl-0'},
                            {text: 'Venc. acima 30 dias', value: 'valorsaldoacima30dias', class:'text-truncate pl-0'},
                            {text: '% Venc. acima 30 dias', value: 'valorpercsaldoacima30dias', align:'center', class:'text-truncate pl-0'},
                            {text: 'Total vencido', value: 'valorsaldogeral', class:'text-truncate pl-0'},
                            {text: 'Caixa a prazo', value: 'valorcaixaprazo', class:'text-truncate pl-0'},
                        ]"
                        :items="dados.map((u) => {
                            return {
                                idempresa: u.idempresa,
                                empresa: u.empresa,
                                valorprevisto: u.valorprevisto,
                                valorpgto: u.valorpgto,
                                valorsaldo: u.valorsaldo,
                                valorsaldo30dias: u.valorsaldo30dias,
                                valorpercsaldo30dias: u.valorpercsaldo30dias,
                                valorsaldoacima30dias: u.valorsaldoacima30dias,
                                valorpercsaldoacima30dias: u.valorpercsaldoacima30dias,
                                valorsaldogeral: u.valorsaldogeral,
                                valorcaixaprazo : u.valorcaixaprazo,
                            };
                        })"
                        hide-default-footer
                        class="elevation-0"
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{item.idempresa }}</td>
                                <td class="text-truncate pl-0">{{item.empresa}}</td>
                                <td class="pl-0">{{item.valorprevisto | formataDinheiro}}</td>
                                <td class="pl-0">{{item.valorpgto | formataDinheiro}}</td>
                                <td class="pl-0">{{item.valorsaldo | formataDinheiro}}</td>
                                <td class="pl-0">{{item.valorsaldo30dias | formataDinheiro}}</td>
                                <td class="text-center text-truncate pr-8 pl-0">{{(item.valorpercsaldo30dias * 100).toFixed(2)}}%</td>
                                <td class="pl-0">{{item.valorsaldoacima30dias | formataDinheiro}}</td>
                                <td class="text-center text-truncate pr-8 pl-0">{{(item.valorpercsaldoacima30dias * 100).toFixed(2)}}%</td>
                                <td class="pl-0">{{item.valorsaldogeral | formataDinheiro}}</td>
                                <td class="pl-0">{{item.valorcaixaprazo | formataDinheiro}}</td>

                            </tr>
                        </template>

                        <template v-slot:foot>
                            <tr class="font-weight-bold blue-grey lighten-4">
                                <td height="30px"></td>
                                <td class="px-4 pl-0">Total</td>
                                <td class="px-4 pl-0">{{dados.map(v => v.valorprevisto).reduce((a,v) => a + v) | formataDinheiro}}</td>
                                <td class="px-4 pl-0">{{dados.map(v => v.valorpgto).reduce((a,v) => a + v) | formataDinheiro}}</td>
                                <td class="px-4 pl-0">{{dados.map(v => v.valorsaldo).reduce((a,v) => a + v) | formataDinheiro}}</td>
                                <td class="px-4 pl-0">{{dados.map(v => v.valorsaldo30dias).reduce((a,v) => a + v) | formataDinheiro}}</td>
                                <td class="text-center text-truncate pl-0 pr-8">{{(dados.map(v => v.valorsaldo30dias).reduce((a,v) => a + v)  /  dados.map(v => v.valorcaixaprazo).reduce((a,v) => a + v)*100).toFixed(2) }}%</td>
                                <td class="px-4 pl-0">{{dados.map(v => v.valorsaldoacima30dias).reduce((a,v) => a + v) | formataDinheiro}}</td>
                                <td class="text-center text-truncate pl-0 pr-8">{{(dados.map(v => v.valorsaldoacima30dias).reduce((a,v) => a + v)  /  dados.map(v => v.valorcaixaprazo).reduce((a,v) => a + v)*100).toFixed(2) }}%</td>
                                <td class="pr-4 pl-0">{{dados.map(v => v.valorsaldogeral).reduce((a,v) => a + v) | formataDinheiro}}</td>
                                <td class="pr-4 pl-0">{{dados.map(v => v.valorcaixaprazo).reduce((a,v) => a + v) | formataDinheiro}}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from "vuex";
import InputDatePicker from "../Widgets/InputDatePicker";
import mixinFilial from "../../util/mixinFilial";
//import GraficoLineColumnDashboard from "../Widgets/GraficoLineColumnDashboard";
import axios from "axios";

export default {
    name: "DashboardFinanceiro",
    mixins: [mixinFilial],
    components: {
        InputDatePicker
    },
    data: () => ({
        sheet: false,
        carregando: false,
        dados: {},
        busca: {
            // dtinicio: `${new Date().getFullYear()}-${
            //     (new Date(
            //         new Date().getFullYear(),
            //         new Date().getMonth() + 1,
            //         0
            //     ).getMonth() +
            //         1 <
            //     10
            //         ? "0"
            //         : "") +
            //     (new Date().getMonth() + 1)
            // }-01`,
            dtinicio:
                `${new Date(new Date().getFullYear(),new Date().getMonth() + 1,0).getFullYear()}-${
                    (new Date( new Date().getFullYear(),new Date().getMonth() + 1,0).getMonth() + 1 < 10 ? "0": "") +(new Date().getMonth() + 1)}-${
                    (new Date( new Date().getFullYear(),new Date().getMonth(),new Date().getDate()).getDate() < 10 ? "0": "") + (new Date().getDate())}`,
            dtfim:
                `${new Date(new Date().getFullYear(),new Date().getMonth() + 1,0).getFullYear()}-${
                (new Date( new Date().getFullYear(),new Date().getMonth() + 1,0).getMonth() + 1 < 10 ? "0": "") +(new Date().getMonth() + 1)}-${
                (new Date( new Date().getFullYear(),new Date().getMonth(),new Date().getDate()).getDate() < 10 ? "0": "") + (new Date().getDate())}`,
            // idfiliais: [],
        },
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "usuario"]),
    },
    methods: {
        get() {
            this.dados = {};
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}dashboard/financeiro/contas-receber`, {
                    dtinicio: this.busca.dtinicio,
                    dtfim: this.busca.dtfim,
                })
                .then((res) => {
                    this.dados = res.data;
                    this.carregando = false;
                })
                .catch(() => {
                    this.carregando = false;
                });
        },
        exportaPlanilha() {
            this.carregando = true;
            return axios
                .post(`${this.backendUrl}dashboard/financeiro/contas-receber/exportar`, {
                    dtinicio: this.busca.dtinicio,
                    dtfim: this.busca.dtfim,
                })
                .then((res) => {
                    this.carregando = false;
                    const url = window.URL.createObjectURL(
                        this.base64ToBlob(res.data)
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "arquivo contas_receber.xlsx");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch(() => {
                    this.carregando = false;
                });
        },
        base64ToBlob(base64) {
            const decodedData = window.atob(base64);
            const uInt8Array = new Uint8Array(decodedData.length);
            for (let i = 0; i < decodedData.length; ++i) {
                uInt8Array[i] = decodedData.charCodeAt(i);
            }
            return new Blob([uInt8Array], { type: "text/plain" });
        },
        async init() {
            await this.get();
            //this.timerRefresh();
        },
    },
    watch: {
        "busca.dtfim": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtinicio = this.busca.dtfim;
            }
        },
        "busca.dtinicio": function () {
            if (this.busca.dtfim < this.busca.dtinicio) {
                this.busca.dtfim = this.busca.dtinicio;
            }
        },
        // autoRefresh: function () {
        //     if (this.autoRefresh) {
        //         this.tempoRefresh = 60;
        //         this.get();
        //     }
        // },
    },
    created() {
        this.init();
    },
};
</script>